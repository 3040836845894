const TerminationReasonCategoriesObj = {
  INTERNAL: 0,
  EXTERNAL: 1,
  UNCLEAR: 2,
  NEW_CLIENT_NOT_INTERESTED: 3,
};
export default {
  Gender: {
    MALE: 0,
    FEMALE: 1,
  },
  InvitationType: { STANDARD: 0, REPLACEMENT: 1 },
  Salutation: {
    DR: 0,
    DR_MED: 1,
    PROF: 2,
    PROF_DR: 3,
    PROF_DR_MED: 4,
  },
  RecommendedBy: {
    NURSING_BASE: 1,
    NURSING_SERVICE: 2,
    NURSING_NAVIGATOR: 3,
    INTERNET: 4,
    FRIEND: 5,
    MDK: 6,
    NEWSPAPER: 7,
    FLYER: 8,
    HEALTH_INSURANCE_LIST: 9,
    CLIENT: 10,
    HELPER: 11,
    OTHER: 12,
    INSTAGRAM: 13,
    EBAY: 14,
    INDEED: 15,
    STUDENT_JOB: 16,
    GOOGLE: 17,
    NURSING_ADVISOR: 67,
  },
  Per: {
    WEEK: 0,
    TWO_WEEKS: 1,
    MONTH: 2,
    QUARTER: 3,
    HALF_YEAR: 4,
    YEAR: 5,
  },
  HelperSituation: {
    STUDENT_1: 0,
    STUDENT_2: 1,
    TRAINEE: 2,
    APPLICANT: 3,
    STUDENT_NEXT_SEMESTER: 4,
    LOOKING_FOR_TRAINING: 5,
    WORKER: 6,
    SOCIAL_SERVICE: 7,
    LOOKING_FOR_JOB: 8,
    OTHER: 9,
  },
  HelperStatus: {
    EMAIL_NOT_VERIFIED: 0,
    EMAIL_VERIFIED: 1,
    ACTIVE: 2,
    INACTIVE: 3,
    TERMINATED: 4,
    INVITEE: 5,
    NOT_INTERESTED: 6,
    NOT_ACCEPTED: 7,
    ACCEPTED: 8,
    FAILED: 9,
  },
  SchoolDegree: {
    NO_DEGREE: 1,
    ELEMENTARY: 2,
    MIDDLE_SCHOOL: 3,
    ABITUR: 4,
    UNKNOWN: 9,
  },
  WorkDegree: {
    NO_DEGREE: 1,
    RECOGNIZED_PROFESSIONAL_TRAINING: 2,
    TECHNICAL_DEGREE: 3,
    BACHELOR: 4,
    DIPLOMA: 5,
    PROMOTION: 6,
    UNKNOWN: 9,
  },
  InsuranceType: {
    LEGAL: 0,
    PRIVATE: 1,
  },
  HealthInsurance: {
    NO_CONTRIBUTION: 0,
    GENERAL_CONTRIBUTION: 1,
    REDUCED_CONTRIBUTION: 2,
    FLAT_RATE: 3,
    REFUND_OF_PRIVATE_INSURANCE: 4,
    VOLUNTARILY_INSURED: 5,
  },
  PensionInsurance: {
    NO_AMOUNT: 0,
    FULL_AMOUNT: 1,
    HALF_AMOUNT: 2,
    FLAT_RATE: 3,
    EMPLOYED: 4,
    PENSION_INSURANCE_REFUND: 5,
  },
  DrivingMethod: {
    FOOT: 0,
    LOCAL_PUBLIC_TRANSPORT: 1,
    BICYCLE: 2,
    CAR: 3,
    MOTORCYCLE: 4,
    SCOOTER: 5,
  },
  InvitationStatus: {
    PENDING: 0,
    ACCEPTED: 1,
    REJECTED: 2,
    EXPIRED: 3,
    HOLD: 4,
    MATCHED: 5,
    FAILED: 6,
    CANCELED: 7,
    COMPLETED: 8,
    SCHEDULED: 9,
  },
  VisitStatus: {
    PLANNED: 0,
    CONFIRMED: 1,
    DECLINED_BY_ADMIN: 2,
  },
  MonthlyVisitStatuses: {
    UNCONFIRMED: 0,
    CONFIRMED: 1,
    DECLINED: 2,
  },
  WorkLocations: {
    BERLIN: 0,
    LEIPZIG: 1,
    DRESDEN: 2,
    CHEMNITZ: 3,
    KÖLN: 4,
    DÜSSELDORF: 5,
    DUISBURG: 6,
    BOCHUM: 7,
    ESSEN: 8,
    DORTMUND: 9,
    FRANKFURT: 10,
    WIESBADEN: 11,
  },
  HelperDocumentTypes: {
    MONTHLY_DOCUMENT: 0,
    CONTRACT: 1,
    WORK_INSTRUCTION: 2,
    TERMINATION: 3,
    CRIMINAL_RECORD: 4,
    ID: 5,
    RESIDENCE_PERMIT: 6,
    HIGHER_EDUCATION_CERTIFICATE: 7,
    AU_CERTIFICATE: 8,
    PROOF_3G: 9,
    OTHER_JOB_CONTRACT: 10,
    TRAINING_CERTIFICATE: 11,
    RECORDING_SHEET: 12,
    INCOME_CERTIFICATE: 13,
    LETTER_FROM_MINI_JOB: 14,
    LETTER_FROM_THE_JOB_CENTER: 15,
    TEMPORARY_CONTRACT: 19,
    OTHER: 100,
  },
  ClientDocumentTypes: {
    MONTHLY_DOCUMENT: 0,
    CONTRACT: 1,
    DECLARATION_OF_ASSIGNMENT: 2,
    TERMINATION: 3,
    SUPERVISOR_ID: 4,
    PREVENTION_CARE: 5,
    TEMPORARY_CONTRACT: 19,
    OTHER: 100,
  },
  InvoiceLetterTypes: {
    INVOICE: 2,
    INVOICE_REMINDER: 3,
  },
  ClientLetterTypes: {
    CONTRACT: 1,
    OTHER: 100,
  },
  LetterTypes: {
    CLIENT_CONTRACT: 1,
    INVOICE: 2,
    INVOICE_REMINDER: 3,
    MANUAL: 4,
    OTHER: 100,
  },
  ClientLetterStatuses: {
    ACCEPTANCE_OF_THE_SHIPMENT: 1,
    PROCESSING_OF_THE_SHIPMENT: 2,
    DELIVERY_TO_THE_PRINTING_CENTER: 3,
    PROCESSING_IN_PRINTING_CENTER: 4,
    ERROR: 99,
  },
  ClientContractTypes: {
    VPOnly: 3,
    VPKP_Only: 4,
    Selbstzahler: 5,
    Entlastungsbetrag: 6,
    Verhinderungspflege: 7,
    Kurzeitspflege: 8,
  },
  OtherDocumentsTypes: {
    AntragVP: 11,
    Rechnungskopie: 12,
    Kündigungsbestätigung: 13,
    Musterbrief: 14,
    Confirmation: 15,
    InformationVP: 16,
    'Nicht Erreichbar': 17,
    Buchungplanänderung: 18,
    'Gratis Stunde': 19,
    'Information VP Yearly': 20,
    'Antrag VP Januar Aktion 2024': 21,
    'Antrag VP KP': 22,
    InformationVPKP: 23,
    'New Client Flyer': 24,
    'Cost Estimation': 25,
  },
  HelperContractTypes: {
    '70_h': 9,
    '450_h': 10,
  },
  OtherHelperDocumentsTypes: {
    'Job Reference': 100,
  },
  ActiveHelpers: {
    HAS_HELPER: 1,
    HAS_NO_HELPER: 0,
  },
  ShowPersonalInfo: {
    NOT_SHOW_PHONE_NUMBER: false,
    SHOW_PHONE_NUMBER: true,
  },
  ClientStatus: {
    INACTIVE: 0,
    DRAFT: 1,
    ACTIVE: 2,
    NOT_INTERESTED: 3,
    TERMINATED: 4,
    FREEZED: 5,
  },
  EmailStatuses: {
    PROCESSED: 0,
    DELIVERED: 1,
    OPEN: 2,
    CLICK: 3,
    DROPPED: 4,
    DEFERRED: 5,
    BOUNCE: 6,
    SPAM_REPORT: 7,
    UNSUBSCRIBE: 8,
    GROUP_UNSUBSCRIBE: 9,
    GROUP_RESUBSCRIBE: 10,
  },
  EmailTypes: {
    NOTIFICATION: 0,
    VERIFICATION: 1,
    RESET_PASSWORD: 2,
    HELPER_INTERVIEW_DECLINED: 3,
    HELPER_INTERVIEW_TOTALLY_DECLINED: 4,
    HELPER_TERMINATION_ACCEPTED: 5,
    INVOICE_COPY: 6,
    INVOICE: 7,
    REMINDER: 8,
    VISIT_REPORT8: 9,
    HELPER_INTERVIEW_ACCEPTED: 10,
    UNDER_AGED_HELPER_PARENT_EMAIL: 11,
    UNDER_AGED_HELPER_PARENT_EMAIL_CONTRACT_SIGNED: 12,
  },
  VaccinationTypes: {
    RECOVERED: -1,
    0: 0,
    1: 1,
    2: 2,
    3: 3,
    4: 4,
  },
  InvoiceTypes: {
    EB_LAST_YEAR: 0,
    EB: 1,
    VP: 2,
    B_EB: 3,
    B_VP: 4,
    PRIVATE_EXTRA: 5,
    PRIVATE_ALL: 6,
  },
  OrderBy: {
    FIRST_NAME: 'firstName',
    LAST_NAME: 'lastName',
    CREATED_AT: 'createdAt',
  },
  StatisticsHelpersOrderBy: {
    FIRST_NAME: 'firstName',
    LAST_NAME: 'lastName',
    CREATED_AT: 'createdAt',
    START_DATE: 'startDate',
    INTERVIEW_DATE: 'interviewDate',
  },
  InvoicesOrderBy: {
    CREATED_AT: 'createdAt',
    DOWNLOAD_COUNT: 'downloadCount',
  },
  EmailsOrderBy: {
    CREATED_AT: 'createdAt',
  },
  InvitationsTypes: {
    STANDARD: 0,
    REPLACEMENT: 1,
  },
  InterviewTypes: {
    YES: 0,
    CANCELED: 1,
    NO_SHOW: 2,
  },
  InterviewStatus: {
    CANCELED: 1,
    NO_SHOW_CONFIRMED: 2,
    NO_SHOW_NOT_CONFIRMED: 3,
    DISCUSS: 4,
    VERIFICATION: 5,
    HELPER_DECLINED: 6,
    TOTALLY_DECLINED: 7,
  },
  InterviewCancelReasons: {
    ANOTHER_JOB: 0,
    NOT_INTERESTED: 1,
    NO_TIME: 2,
    OTHER: 3,
  },
  EducationLevel: {
    TRAINING: 0,
    BACHELOR: 1,
    SCHOOL: 2,
    MASTER: 3,
  },
  ExclusionCriteria: {
    NO_LONG_TERM: 0,
    TOO_YOUNG: 1,
    TOO_OLD: 2,
    NO_KNOWLEDGE_OF_GERMAN: 3,
    NO_NEAT_APPEARANCE: 4,
    MOTIVATION_DOES_NOT_FIT: 5,
    NOT_INFORMED: 6,
    LACK_OF_EXPERIENCE: 7,
  },
  Resources: {
    DROPDOWN_VALUES: 'dropdownValues',
    ADMINS: 'admins',
    HELPERS: 'helpers',
    CLIENTS: 'clients',
    CLIENTS_INVOICES: 'clientsInvoices',
    HELPERS_INVOICES: 'helpersInvoices',
    CLIENTS_DRAFT: 'clientsDraft',
    HELPERS_DRAFT: 'helpersDraft',
    HELPERS_ONBOARDINGS: 'helpersOnboardings',
    MAP: 'map',
    STATISTICS: 'statistics',
    HELPER_TASKS: 'helperTasks',
    DEVELOPERS: 'developers',
    BUGS: 'bugs',
    RANKING: 'ranking',
  },
  StatisticsType: {
    DAILY: 0,
    WEEKLY: 1,
    MONTHLY: 2,
    ANNUALLY: 3,
  },
  PositiveProperties: {
    ACTIVE: 0,
    AMBITIOUS: 1,
    ATTENTIVE: 2,
    DISCIPLINED: 3,
    EHRGEIZIG: 4,
    EXPERIENCED: 5,
    EXTROVERT: 6,
    INTROVERTED: 7,
    HELPFUL: 8,
    COMMUNICATIVE: 9,
    OPEN: 10,
    SELF_EMPLOYED: 11,
    SELF_CONFIDENT: 12,
    SOCIAL: 13,
    GROOMED: 14,
    RELIABLE: 15,
    PUNCTUAL: 16,
    LINGUISTIC: 17,
    FRIENDLY: 18,
  },
  ClientsTasks: {
    NEEDS_HELPER: 0,
    REPLACEMENT: 1,
    NOT_READY_FOR_INVITATION: 2,
    HAS_NO_CONTRACT: 3,
    CHECK_UP_CALL: 4,
    LOOP_WITHOUT_MATCH: 5,
    ACCEPTED_INVITATIONS: 6,
    PENDING_INVITATIONS: 7,
  },
  ClientCheckupCallStatus: {
    NO_CALL: 0,
    NO_ANSWER: 1,
    VOICE_MAIL: 2,
    SATISFIED: 3,
    NOT_SATISFIED: 4,
  },
  InvitationCheckupCallStatus: {
    UNCLARIFIED: 0,
    CLIENT_CONTACTED: 1,
    HELPER_CONTACTED: 2,
  },
  ClientInvoicesStatuses: {
    UNCONFIRMED: 0,
    CONFIRMED: 1,
    DECLINED: 2,
    READY_FOR_INVOICE: 3,
    ALL: 100,
  },
  HelperInvitationCompletionSituation: {
    NO_VISITS: 0,
    CONFIRMED_VISITS: 1,
    MIXED_VISITS: 2,
    PLANNED_OR_DECLINED_VISITS: 3,
  },
  AdminTypes: {
    NORMAL: 0,
    SUPER: 1,
  },
  HelperInvoicesStatuses: {
    UNCONFIRMED: 0,
    CONFIRMED: 1,
    BILLED: 2,
  },
  NotReachingTargetReason: {
    CLIENT_IN_HOSPITAL: 0,
    CLIENT_ON_VACATION: 1,
    HELPER_ON_VACATION: 2,
    CLIENT_NO_NEED: 3,
    HELPER_IN_HOSPITAL: 4,
    VISIT_PROBLEM: 5,
    NO_MORE_TASKS: 6,
    HAVE_NO_CAPACITY: 7,
    CLIENT_NOT_WANT_USE_BUDGET: 8,
    OTHER: 100,
  },
  Days: {
    MONDAY: 0,
    TUESDAY: 1,
    WEDNESDAY: 2,
    THURSDAY: 3,
    FRIDAY: 4,
    SATURDAY: 5,
    SUNDAY: 6,
  },
  PreferredTimes: {
    ALLDAY: 0,
    MORNING: 1,
    AFTERNOON: 2,
  },
  BugStatus: {
    NOT_RESOLVED: 0,
    RESOLVED: 1,
  },
  Months: {
    JANUARY: 1,
    FEBRUARY: 2,
    MARCH: 3,
    APRIL: 4,
    MAY: 5,
    JUNE: 6,
    JULY: 7,
    AUGUST: 8,
    SEPTEMBER: 9,
    OCTOBER: 10,
    NOVEMBER: 11,
    DECEMBER: 12,
  },
  CompleteInvitationReasons: {
    CLIENT_TERMINATED: 0,
    HELPER_TERMINATED: 1,
    OTHER: 100,
  },
  CancelInvitationReasons: {
    WRONG_INVITATION: 0,
    CLIENT_TEMPORARILY_UNAVAILABLE: 1,
    OTHER: 100,
  },
  CreateInvitationReasons: {
    HELPER_TERMINATED: 0,
    HELPER_VACATION: 1,
  },
  HelperTerminationStatuses: {
    REQUESTED: 0,
    ACCEPTED: 1,
    DONE: 2,
    DECLINED: 3,
  },
  AutomaticInvitationStages: {
    PREFERRED_MATCHING: 0,
    PREFERENCES_COMPROMISE: 1,
    RADIUS_COMPROMISE: 2,
    BONUS_COMPROMISE: 3,
  },
  HelperVacationStatuses: {
    REQUESTED: 0,
    ACCEPTED: 1,
    DECLINED: 2,
  },
  VacationReplacementInvitationStatuses: {
    PENDING: 0,
    HELPER_FOUND: 1,
  },
  NoReplacementNeededReason: {
    CLIENT_NO_NEED: 0,
    CLIENT_ON_VACATION: 1,
    HOURS_COMPLETED: 2,
    OTHER: 100,
  },

  TerminationReasonCategories: TerminationReasonCategoriesObj,

  ClientTerminationReasons: {
    [TerminationReasonCategoriesObj.INTERNAL]: {
      BILLING: 0,
      MATCHING: 1,
      HELPER_QUALITY: 2,
      SUPPORT: 3,
    },
    [TerminationReasonCategoriesObj.EXTERNAL]: {
      CLIENT_DIED: 0,
      MOVE_TO_NURSING_HOME: 1,
      TERMINATED_BY_JUHI: 2,
      TEMPORARY_ORDER: 3,
      MISSING_SERVICE_COVERAGE_LOCATION: 5,
      MISSING_SERVICE_COVERAGE_TYPE: 6,
    },
    [TerminationReasonCategoriesObj.UNCLEAR]: {
      LONG_INACTIVITY: 0,
      OTHER: 100,
    },
    [TerminationReasonCategoriesObj.NEW_CLIENT_NOT_INTERESTED]: {
      NO_MORE_INTEREST: 0,
    },
  },

  HelperTerminationReasons: {
    [TerminationReasonCategoriesObj.INTERNAL]: {
      ORDER_SUPPLY: 0,
      TASKS: 1,
      CLIENTS: 2,
      SUPPORT: 3,
      PAYMENT: 4,
    },
    [TerminationReasonCategoriesObj.EXTERNAL]: {
      TIME_AVAILABILITY_SCHOOL: 0,
      TIME_AVAILABILITY_VOCATIONAL_TRAINING: 1,
      TIME_AVAILABILITY_STUDIES: 2,
      TIME_AVAILABILITY_FSJ: 3,
      TIME_AVAILABILITY_OTHER: 4,
      PRIVATE_CIRCUMSTANCES: 5,
      TERMINATED_BY_JUHI: 6,
      MOVE_TO_ANOTHER_CITY: 7,
      NO_MORE_INTEREST: 8,
    },
    [TerminationReasonCategoriesObj.UNCLEAR]: {
      LONG_INACTIVITY: 0,
      OTHER: 100,
    },
  },
  PrivateInvoicesStatuses: {
    CREATED: 0,
    SENT: 1,
    PAID: 2,
    CANCELLED: 3,
    ALL: 100,
  },
  ContractLaterReasons: {
    CONTRACT_NOT_RECEIVED: 0,
    CONTRACT_FAULTY: 1,
    RESEND_REQUESTED: 2,
    OTHER: 100,
  },

  JiraStatuses: {
    TODO: 'To Do',
    IN_PROGRESS: 'In Progress',
    REVIEW: 'Review',
    TESTING: 'Testing',
    DONE: 'Done',
  },

  HelperLevel: {
    NONE: 0,
    BRONZE: 1,
    SILVER: 2,
    GOLD: 3,
    DIAMOND: 4,
  },

  ServicePlan: {
    BASIC: 0,
    BEST_CHOICE: 1,
    PREMIUM: 2,
  },

  NewClientsTypes: {
    INACTIVE_SCHEDULED: 0,
    NOT_ACCEPTED: 1,
    ACCEPTED: 2,
    NOT_MATCHED: 3,
    MATCHED_WITH_NO_FEEDBACK: 4,
    NO_FUTURE_VISIT: 5,
  },
  HelperDocumentRequestStatus: {
    ACCEPTED: 1,
    DECLINED: 2,
  },

  HelperRankingEvents: {
    VISIT_TWO_CLIENTS: 0,
    FULFILL_SERVICE_PLANS_OF_CLIENTS: 1,
    CONFIRM_MONTHLY_VISITS_ON_TIME: 2,
    SUBMIT_VISIT_REPORTS_ON_TIME: 3,
    NORMAL_PRAISE: 4,
    EXTRAORDINARY_PRAISE: 5,
    REACT_TO_INVITATION_WITHIN_24_HOURS: 6,
    PLAN_FIRST_VISIT_WITHIN_24_HOURS: 7,
    DAILY_LOGIN: 8,
    PUSH_NOTIFICATIONS_TURNED_ON_FOR_WHOLE_MONTH: 9,
    COMPLETED_VISIT_HOUR: 10,
    RECOMMEND_CLIENT: 11,
    RECOMMEND_HELPER: 12,
    COMPLAINT: 13,
    BIG_COMPLAINT: 14,
    FULFILL_SERVICE_PLAN_AND_BUDGET_OF_CLIENT: 15,
  },
  InvitationDeclineReason: {
    DISTANCE_TIME_TOO_LONG: 0,
    ORDER_UNCLEAR: 1,
    EXCESSIVE_AMOUNT: 2,
    TOO_LITTLE_SCOPE: 3,
    TYPE_OF_SERVICE: 4,
    OTHER: 100,
  },
  TargetNotReachedType: {
    SERVICE_HOURS: 0,
    BUDGET: 1,
  },
  TasksCompletedWithinTime: {
    NO: 0,
    RATHER_NO: 1,
    NEUTRAL: 2,
    RATHER_YES: 3,
    YES: 4,
  },
  ServiceTypeAnswers: {
    HOME_HELP: 0,
    SHOPPING: 1,
    CARE_ACCOMPANIMENT: 2,
    COMBINATION: 3,
    OTHER: 100,
  },
  ClientHourlyRate: {
    OLD_PRICE_2022: 25,
    OLD_PRICE_2023: 30,
    NEW_PRICE: 31.25,
    CUSTOM_CITY_PRICE: 25,
  },

  AdminInvitationCreationReason: {
    HELPER_CHANGE_CLIENT_REQUEST: 0,
    HELPER_CHANGE_HELPER_REQUEST: 1,
    HELPER_CHANGE_BLACKLIST_HELPER: 2,
    ADD_HELPER_BUDGET_USAGE: 3,
    ADD_HELPER_OTHER_TASKS: 4,
    INITIAL_ALLOCATION_NK: 5,
    REPLACEMENT: 6,
    OTHER: 100,
  },

  OnboardingSectionStatus: {
    NORMAL: 0,
    COMPLETED: 1,
    INCOMPLETE: 2,
    PENDING: 3,
    DECLINED: 4,
    DISABLED: 5,
    PROCESSING: 6,
  },

  HelperOtherJobTypes: {
    FULL_TIME: 0,
    PART_TIME: 1,
    TRAINING: 2,
    MINI_JOB: 3,
    SHORT_TERM: 4,
  },

  FamilyStatus: {
    SINGLE: 0,
    MARRIED: 1,
  },

  ContactStatusOptions: {
    NO_CONTACT: 0,
    FIRST_CONTACT: 1,
    SECOND_CONTACT: 2,
  },

  UnclearClientsProcessingStatus: {
    unProcessed: 0,
    HelperContactedByMail: 1,
    HelperContactedByCall: 2,
    ClientContactedByMail: 3,
    ClientContactedByCall: 4,
    ClientContactedByPost: 5,
  },

  ContractTypeName: {
    '109': '109 (Mini Job)',
    '110': '110 (70 Tage)',
  },
};
